/* eslint-disable @typescript-eslint/naming-convention */

export const translations = {
  US: {
    Info: 'Find the cheapest option by just scanning the barcode',
    Description:
      'We will search all possible options for you. All you need is just to scan the barcode. We will show the best price and location!',
    Facts: 'Quick Facts',
    FactsContent: `
    <ul>
      <li>
        The app was created in February, 2022. The original goal was identifing russian-made products.
      </li>
      <li>
        The Russian Federation is currently using the same strategy in Ukraine, occupying their territories by force.
      </li>
      <li>
        The Russian Federation has occupied territories of Moldova.
      </li>
      <li>
        The Russian Federation is not satisfied, and now plans to occupy additional territories from Ukraine.
      </li>
    </ul>
  `,
  },
  GE: {
    Info: 'დაასკანირე პროდუქტი და გაიგე სად იყიდი ყველაზე იაფად',
    Description:
      'გამოყენება ძალიან მარტივია, თქვენ მხოლოდ უნდა დაასკანიროთ პროდუქტის ბარკოდი, ჩვენ მომენტალურად განახებთ თუ სად შეგიძლიათ იყიდოთ ეს პროდუქტი, ყველაზე კარგ ფასად!',
    Facts: 'ფაქტები',
    FactsContent: `
    <ul>
      <li>
        2008 წელს რუსეთის ფედერაციას ჰქონდა ომი საქართველოსთან და დღეს საქართველოს ტერიტორიის 20% ოკუპირებულია რუსეთის არმიის მიერ.
      </li>
      <li>
       რუსეთის ფედერაციამ იგივე სტრატეგიით მოახდინა უკრაინის ტერიტორიის ოკუპაცია
      </li>
      <li>
        რუსეთის ფედერაციას ასევე ოკუპირებული აქვს მოლდოვის ტერიტორიები
      </li>
      <li>
        დღეს რუსეთის ფედერაცია ცდილობს მეტი ტერიტორია მიითვისოს უკრაინის სახელმწიფოსგან
      </li>
    </ul>
  `,
  },
  // UA: {
  //   Info: 'Скануй продукти та знаходь країну виробника',
  //   Description:
  //     'Кожного разу як ви купуєте російський продукт, ваші гроші використовуються для атак на незалежні країни, такі як Грузія, Україна, Молдова та інші',
  //   Facts: 'Деякі факти',
  //   FactsContent: `
  //   <ul>
  //     <li>
  //       У 2008 році Російська Федерація воювала з Грузією, і на даний момент російська армія займає 20% грузинської території.
  //     </li>
  //     <li>
  //       Російська Федерація використовувала таку ж стратегію з Україною і силою окупувала частіну її територій.
  //     </li>
  //     <li>
  //       Російська Федерація також окупувала частину території Молдови.
  //     </li>
  //     <li>
  //       Російська Федерація все ще хоче окупувати більше і тепер планує окупувати додаткові території України.
  //     </li>
  //   </ul>
  // `,
  // },
};
